import DataManager from './dtm';
import { fbDatabase } from '../firebase/firebase';


async function queryBy10(ownerId, ids, dataIni, dataFim, fromCache) {
    let query = fbDatabase.collection('resgates');

    if (ownerId) {
        query = query.where('ownerId', '==', ownerId);
    }

    query = query
        .where('data', '>=', dataIni)
        .where('data', '<=', dataFim)
        .where('convenio.id', 'in', ids);

    const snapshot = await new DataManager().getQueryData(query, fromCache);

    if (snapshot.empty) {
        return [];
    }

    const data = snapshot.docs.map((item) => ({
        key: item.id,
        ...item.data(),
    }));

    return data;
}

class ResgatesDB extends DataManager {

    state = {
        collection: 'resgates'
    }

    async getByPeriodo(dataIni, dataFinal, ownerId, conveniosIds) {
        if (conveniosIds && conveniosIds.constructor !== Array) {
            const conv = []
            conv.push({ key: conveniosIds });
            conveniosIds = conv;
        }

        let pontos = [];
        if (!conveniosIds || conveniosIds.length === 0) {
            let query = fbDatabase
                .collection(this.state.collection)
                .where('data', '>=', dataIni)
                .where('data', '<=', dataFinal)

            if (ownerId) {
                query += query.where('ownerId', '==', ownerId)
            }

            const snapshot = await this.getQueryData(query, false);

            const data = snapshot.docs.map((item) => ({
                key: item.id,
                ...item.data(),
            }));

            pontos.push.apply(pontos, data);
            return pontos;
        }

        // Quando houver convenios ids informados na lista

        let ids = [];
        if (!conveniosIds) {
            conveniosIds = []
        }

        for (let index = 0; index < conveniosIds.length; index++) {
            ids.push(conveniosIds[index].key);
            if (ids.length >= 10) {
                const data = await queryBy10(ownerId, ids, dataIni, dataFinal, false);
                pontos.push.apply(pontos, data);
                ids = [];
            }
        }

        if (ids.length > 0) {
            const data = await queryBy10(ownerId, ids, dataIni, dataFinal, false);
            pontos.push.apply(pontos, data);
        }

        return pontos;
    }

    async getByParticipante(beneficiarioId, convenioId, dataIni, dataFim) {

        let resgates = [];

        let query = fbDatabase
            .collection(this.state.collection)
            .where('beneficiarioId', '==', beneficiarioId)
            .where('convenio.id', '==', convenioId);

        if (dataIni && dataFim) {
            query = query.where('data', '>=', dataIni);
            query = query.where('data', '<=', dataFim);
        }

        query = query.orderBy('data', 'desc');

        const snapshot = await this.getQueryData(query, false);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
        }));

        resgates.push.apply(resgates, data);

        return resgates;
    }

    async getByConvenio(convenioId, dataIni, dataFim) {
        let resgates = [];

        let query = fbDatabase
            .collection(this.state.collection)
            .where('convenio.id', '==', convenioId);

        if (dataIni && dataFim) {
            query = query.where('data', '>=', dataIni);
            query = query.where('data', '<=', dataFim);
        }

        query = query.orderBy('data', 'desc');

        const snapshot = await this.getQueryData(query, false);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
        }));

        resgates.push.apply(resgates, data);

        return resgates;
    }

}

const resgateDB = new ResgatesDB();
export default resgateDB;