export const IDX_MOUB_DEV = 0;
export const IDX_MOUB = 1;
export const IDX_CONVENIO = 2;

export const PROJECTS = [{
    id: IDX_MOUB_DEV,
    project: 'payments-4c',
    title: 'Payments',
    meta: {
        description: 'Conheça o mais novo e completo sistema de débito e crédito, cashback e fidelidade que vai revolucionar o seu negócio',
        keywords: 'supermercado supermercados mercado feira teresópolis débito  cesta básica alimento cards alterdata'
    }
}, {
    id: IDX_MOUB,
    project: 'moub-4c',
    title: 'Moub',
    meta: {
        description: 'Conheça o mais novo e completo sistema de débito e crédito, cashback e fidelidade que vai revolucionar o seu negócio',
        keywords: 'supermercado supermercados mercado feira teresópolis débito  cesta básica alimento cards alterdata'
    }
}, {
    id: IDX_CONVENIO,
    project: 'convenio-37457',
    title: 'Regina+',
    meta: {
        description: 'Sistema de débito da rede de supermercados Regina em Teresópolis/RJ',
        keywords: 'supermercado supermercados mercado feira teresópolis débito mais regina reginamais maisregina reginas cesta básica alimento cards alterdata mas'
    }
}];
