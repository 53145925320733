import moment from 'moment';
import beneficiarioDB from '../../../dataManager/dtmBeneficiario';
import pontoDB from '../../../dataManager/dtmPontos';


function round(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}



export async function buildChart(dataInicial, dataFinal, ownerId, conveniosIds, fromCache) {

  const inicio = dataInicial.startOf('day').toDate();
  const fim = dataFinal.endOf('day').toDate();

  const snapshotPontos = await pontoDB.getByPeriodo(inicio, fim, ownerId, conveniosIds, fromCache);
  const snapshotBeneficiarios = await beneficiarioDB.getByConvenioIdAndPeriodo(conveniosIds[0].key, inicio, fim, fromCache);

  let reducedPorData = snapshotPontos.reduce((result, arr) => {
    const id = secsToDate(arr.data.seconds);
    result[id] = result[id] || [];

    let pontos = round(arr.pontos, 2);
    let baixados = round(arr.baixados, 2);
    let valueInCents = arr.valueInCents;

    result[id] = {
      pontos: result[id].pontos ? round(result[id].pontos + pontos, 2) : pontos,
      baixados: result[id].baixados ? round(result[id].baixados + baixados, 2) : baixados,
      valueInCents: result[id].valueInCents ? result[id].valueInCents + valueInCents : valueInCents,
      valueStr: result[id].valueInCents ? pontoDB.formatarMoeda((result[id].valueInCents + valueInCents) / 100) : pontoDB.formatarMoeda(valueInCents / 100),
      data: moment(arr.data.seconds * 1000),
    }

    return result;
  }, []);

  reducedPorData = fillEmptyDays(dataInicial, dataFinal, reducedPorData);

  // Reduced por data dos beneficiários agora
  let reducedPorDataBnf = snapshotBeneficiarios.reduce((result, arr) => {
    const id = secsToDate(arr.cadastro.seconds);
    result[id] = result[id] || [];
    result[id] = {
      novos: result[id].novos ? result[id].novos + 1 : 1,
      data: moment(arr.cadastro.seconds * 1000),
    }

    return result;
  }, []);

  reducedPorDataBnf = fillEmptyDaysForBnfs(dataInicial, dataFinal, reducedPorDataBnf);

  for (let index = 0; index < reducedPorData.length; index++) {
    const item = reducedPorData[index];
    item.novosClientes = reducedPorDataBnf[index].novos;
  }

  /* eslint-disable no-unused-vars */
  let reducedPorConvenio = snapshotPontos.reduce((result, arr) => {

    const id = arr.convenio.id;
    if (id) {
      result[id] = result[id] || [];
    }

    result[id] = {
      pontos: result[id].pontos ? round(result[id].pontos + arr.pontos, 2) : round(arr.pontos, 2),
      baixados: result[id].baixados ? round(result[id].baixados + arr.baixados, 2) : round(arr.baixados, 2),
      valueInCents: result[id].valueInCents ? result[id].valueInCents + arr.valueInCents : arr.valueInCents,
      valueStr: result[id].valueInCents ? pontoDB.formatarMoeda((result[id].valueInCents + arr.valueInCents) / 100) : pontoDB.formatarMoeda(arr.valueInCents / 100),
      novosClientes: result[id].novosClientes ? result[id].novosClientes + arr.novosClientes : 0,
      convenio: arr.convenio,
    }

    return result;
  }, []);

  let tableData = [];

  reducedPorConvenio = await Promise.all(Object.values(reducedPorConvenio).map(async (item) => {
    let key = 0;

    const element = {
      key: key,
      convenio: item.convenio,
      pontos: item.pontos,
      baixados: item.baixados,
      valueInCents: item.valueInCents,
      valueStr: item.valueStr,
      novosClientes: item.novosClientes
    }

    key++;
    tableData.push(element);
  }));

  return {
    chartData: reducedPorData,
    tableData: tableData
  };
}

function fillEmptyDays(inicio, fim, info) {
  const days = fim.diff(inicio, "days");
  let novoInfo = [];

  for (let i = 0; i <= days; i++) {
    let data = moment(fim).startOf("day").subtract(i, "days");
    let item = Object.values(info).find((element) => element.data.startOf("day").diff(data, "days") === 0);
    if (item) {
      novoInfo.push({
        pontos: item.pontos,
        baixados: item.baixados,
        valueInCents: item.valueInCents,
        data: item.data.toDate()
      })
    } else {
      const momentData = moment(data)
      novoInfo.push({
        pontos: 0,
        baixados: 0,
        valueInCents: 0,
        data: data.toDate()
      })
    }
  }
  novoInfo = novoInfo.sort((a, b) => a.data > b.data ? 1 : -1)
  return novoInfo;
}

function fillEmptyDaysForBnfs(inicio, fim, info) {
  const days = fim.diff(inicio, "days");
  let novoInfo = [];

  for (let i = 0; i <= days; i++) {
    let data = moment(fim).startOf("day").subtract(i, "days");
    let item = Object.values(info).find((element) => element.data.startOf("day").diff(data, "days") === 0);
    if (item) {
      novoInfo.push({
        novos: item.novos,
        data: item.data.toDate()
      })
    } else {
      novoInfo.push({
        novos: 0,
        data: data.toDate()
      })
    }
  }
  novoInfo = novoInfo.sort((a, b) => a.data > b.data ? 1 : -1)
  return novoInfo;
}



function secsToDate(secs) {
  var t = moment(secs * 1000)
  return t.date() + "/" + (t.month() + 1);
}