import React, { Component } from 'react';
import InputMask from 'react-input-mask';


class SearchInput extends Component {
    render() {
        return (
            <InputMask {...this.props} />
        )
    }
}

export default SearchInput;