import React from "react";
import InputMask from "react-input-mask";

class CPFInput extends React.Component {

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(field) {
    if (this.props.onChange) {
      this.props.onChange(field);
    }
  }

  // mask="999.999.999-99"
  render() {

    const n = this.props.value.replace(/\D/g, "");
    let mask = n.length <= 11 ? '999.999.999-99' : '99.999.999/9999.99';
    if (n.length >= 11 && !isCPF(this.props.value)) {
      mask = '99.999.999/9999.99';
    }

    return <InputMask
      type="tel"
      placeholder={this.props.placeholder !== undefined && this.props.placeholder !== null ? this.props.placeholder : "Digite o CPF ou CNPJ"}
      mask={mask}
      onChange={this.onChange}
      className={this.props.className}
      value={this.props.value}
      disabled={this.props.disabled}
      onKeyDown={this.props.onKeyDown} />
  }
}

function isCPF(cpf) {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf === '') return false;
  // Elimina CPFs invalidos conhecidos	
  if (cpf.length !== 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999")
    return false;
  // Valida 1o digito	
  let add = 0;
  for (let i = 0; i < 9; i++)
    add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11)
    rev = 0;
  if (rev !== parseInt(cpf.charAt(9)))
    return false;
  // Valida 2o digito	
  add = 0;
  for (let i = 0; i < 10; i++)
    add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11)
    rev = 0;
  if (rev !== parseInt(cpf.charAt(10)))
    return false;
  return true;
}


export default CPFInput;