import React, { Component } from 'react';

import {
    Button,
    Divider,
    Icon,
    Input,
    Select,
    Tabs,
    Tooltip
} from 'antd';

import { withRouter } from 'react-router';
import { getConvenio } from '../../auth/auth-provider';
import { isCNPJ, isCPF } from '../../funcs/utils';

import CPFInput from '../../masks/cpf';
import TelefoneInput from '../../masks/telefone';
import beneficiarioDB from '../../../dataManager/dtmBeneficiario';
import mensagem from '../../messages/message';

import './BeneficiariosCRUD.css';


const { TabPane } = Tabs;
const { Option } = Select;


class BeneficiariosCRUD extends Component {
    state = {
        loading: false,
        data: {
            key: '',
            ativo: true,
            nome: '',
            sobrenome: '',
            cpf: '',
            email: '',
            endereco: {
                logradouro: '',
                bairro: '',
                cidade: '',
                uf: '',
                telefone: ''
            }
        }
    }

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateData = this.validateData.bind(this);

        this.onChangeGeral = this.onChangeGeral.bind(this);
        this.onChangeEndereco = this.onChangeEndereco.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
    }

    componentDidMount() {
        if (this.props.record) {
            const {
                key,
                ativo,
                nome,
                sobrenome,
                cpf,
                email,
                endereco
            } = this.props.record;

            const {
                logradouro,
                bairro,
                cidade,
                uf,
                telefone
            } = endereco;

            const data = {
                key,
                ativo,
                nome,
                sobrenome,
                cpf,
                email: email ?? '',
                endereco: {
                    logradouro,
                    bairro,
                    cidade,
                    uf,
                    telefone
                }
            };

            this.setState({ data });
        }
    }

    async handleSubmit() {
        const { data } = this.state;

        this.setState({ loading: true });

        const isOk = await this.validateData(data);

        if (!isOk) {
            this.setState({ loading: false });
            return;
        }

        const {
            ativo,
            nome,
            sobrenome,
            cpf,
            email,
            endereco
        } = data;

        const item = {
            ativo,
            nome,
            sobrenome,
            cpf,
            email,
            endereco: {
                ...endereco
            }
        };

        await beneficiarioDB.update(
            data.key,
            item
        );


        data['ativoStr'] = ativo ? 'Sim' : 'Não';

        mensagem.openNotificationWithIcon(
            'success',
            'Perfeito',
            'O beneficiário foi atualizado com sucesso',
            3
        );

        this.props.handleOk(data);
        this.setState({ loading: false });
    }

    async validateData(data) {
        const {
            key,
            nome,
            sobrenome,
            cpf,
            email,
            endereco
        } = data;

        const { ownerId } = await getConvenio();

        // Verificar se Nome e Sobrenome foram informados
        if (nome === '' || sobrenome === '') {
            mensagem.openNotificationWithIcon(
                'error',
                'Cadastro Incompleto',
                'Beneficiário deve ter nome e sobrenome',
                3
            );

            return false;
        }

        // Verificar se pelo menos CPF/CNPJ ou Telefone é informado
        if (cpf === '' && endereco.telefone === '') {
            mensagem.openNotificationWithIcon(
                'error',
                'Cadastro Incompleto',
                'Beneficiário deve possuir ao menos CPF/CNPJ ou Telefone',
                3
            );

            return false;
        }

        // Verificar se o CPF/CNPJ é válido
        if (cpf !== '' && (
            (cpf.length === 14 && !isCPF(cpf)) ||
            (cpf.length === 18 && !isCNPJ(cpf))
        )) {
            mensagem.openNotificationWithIcon(
                'error',
                'CPF/CNPJ Inválido',
                'O CPF/CNPJ informado não está correto',
                3
            );

            return false;
        }

        // Verificar se o CPF/CNPJ está duplicado
        if (cpf !== '') {
            const beneficiario = await beneficiarioDB.getByCPFAndOwnerId(cpf, ownerId);

            if (beneficiario !== undefined && beneficiario.key !== key) {
                mensagem.openNotificationWithIcon(
                    'error',
                    'CPF/CNPJ já Cadastrado',
                    'O CPF/CNPJ informado já está cadastrado em outro beneficiário',
                    3
                );

                return false;
            }
        }

        // Verificar se o Telefone está duplicado
        if (endereco.telefone !== '') {
            const beneficiario = await beneficiarioDB.getByPhoneNumberAndOwnerId(endereco.telefone, ownerId);

            if (beneficiario !== undefined && beneficiario.key !== key) {
                mensagem.openNotificationWithIcon(
                    'error',
                    'Telefone já Cadastrado',
                    'O telefone informado já está cadastrado em outro beneficiário',
                    3
                );

                return false;
            }
        }

        // Verificar se o Email está duplicado
        if (email !== '') {
            const beneficiario = await beneficiarioDB.getByEmailAndOwnerId(email, ownerId);

            if (beneficiario !== undefined && beneficiario.key !== key) {
                mensagem.openNotificationWithIcon(
                    'error',
                    'Email já Cadastrado',
                    'O email informado já está cadastrado em outro beneficiário',
                    3
                );

                return false;
            }
        }

        return true;
    }

    onChangeGeral(e, varName) {
        const { data } = this.state;
        data[varName] = e.target.value;

        this.setState({ data });
    }

    onChangeEndereco(e, varName) {
        const { data } = this.state;
        data.endereco[varName] = e.target.value;

        this.setState({ data });
    }

    onChangeSelect(value, varName) {
        const { data } = this.state;
        data[varName] = value;

        this.setState({ data });
    }

    render() {
        return (
            <div>
                <Tabs defaultActiveKey='geral'>
                    <TabPane tab='Geral' key='geral'>
                        <div>
                            <label>
                                Nome&nbsp;
                                <Tooltip title='Qual é o nome?'>
                                    <Icon type='question-circle-o' />
                                </Tooltip>
                            </label>

                            <Input
                                placeholder='Nome'
                                value={this.state.data.nome}
                                onChange={(e) => this.onChangeGeral(e, 'nome')}
                            />
                        </div>

                        <div>
                            <label>
                                Sobrenome&nbsp;
                                <Tooltip title='Qual é o sobrenome?'>
                                    <Icon type='question-circle-o' />
                                </Tooltip>
                            </label>

                            <Input
                                placeholder='Sobrenome'
                                value={this.state.data.sobrenome}
                                onChange={(e) => this.onChangeGeral(e, 'sobrenome')}
                            />
                        </div>

                        <div>
                            <label>
                                CPF/CNPJ&nbsp;
                                <Tooltip title='Qual é o CPF/CNPJ?'>
                                    <Icon type='question-circle-o' />
                                </Tooltip>
                            </label>

                            <CPFInput
                                className='inputCrud'
                                value={this.state.data.cpf}
                                onChange={(e) => this.onChangeGeral(e, 'cpf')}
                            />
                        </div>

                        <div>
                            <label>
                                Celular&nbsp;
                                <Tooltip title='Qual é o celular?'>
                                    <Icon type='question-circle-o' />
                                </Tooltip>
                            </label>

                            <TelefoneInput
                                className='inputCrud'
                                value={this.state.data.endereco.telefone}
                                onChange={(e) => this.onChangeEndereco(e, 'telefone')}
                            />
                        </div>

                        <div>
                            <label>
                                Email&nbsp;
                                <Tooltip title='Qual é o email?'>
                                    <Icon type='question-circle-o' />
                                </Tooltip>
                            </label>

                            <Input
                                placeholder='Email'
                                value={this.state.data.email}
                                onChange={(e) => this.onChangeGeral(e, 'email')}
                            />
                        </div>

                        <div>
                            <label>
                                Ativo&nbsp;
                                <Tooltip title='Esse usuário está ativo?'>
                                    <Icon type='question-circle-o' />
                                </Tooltip>
                            </label>

                            <div>
                                <Select
                                    className='ativoSelect'
                                    style={{ width: '30%' }}
                                    value={this.state.data.ativo}
                                    onChange={(value) => this.onChangeSelect(value, 'ativo')}
                                >
                                    <Option value={true}>Sim</Option>
                                    <Option value={false}>Não</Option>
                                </Select>
                            </div>
                        </div>
                    </TabPane>

                    <TabPane tab='Endereço' key='endereco'>
                        <div>
                            <label>Endereço</label>

                            <Input
                                placeholder='Endereço'
                                value={this.state.data.endereco.logradouro}
                                onChange={(e) => this.onChangeEndereco(e, 'logradouro')}
                            />
                        </div>

                        <div>
                            <label>Bairro</label>

                            <Input
                                placeholder='Bairro'
                                value={this.state.data.endereco.bairro}
                                onChange={(e) => this.onChangeEndereco(e, 'bairro')}
                            />
                        </div>

                        <div>
                            <label>Cidade</label>

                            <Input
                                placeholder='Cidade'
                                value={this.state.data.endereco.cidade}
                                onChange={(e) => this.onChangeEndereco(e, 'cidade')}
                            />
                        </div>

                        <div>
                            <label>UF</label>

                            <Input
                                placeholder='UF'
                                value={this.state.data.endereco.uf}
                                onChange={(e) => this.onChangeEndereco(e, 'uf')}
                                maxLength={2}
                            />
                        </div>
                    </TabPane>
                </Tabs>

                <div className='modalButtons'>
                    <Button
                        onClick={this.props.handleCancel}
                    >
                        Cancelar
                    </Button>

                    <Divider type='vertical' />

                    <Button
                        type='primary'
                        loading={this.state.loading}
                        onClick={this.handleSubmit}
                    >
                        Salvar
                    </Button>
                </div>
            </div>
        )
    }
}

export default withRouter(BeneficiariosCRUD);