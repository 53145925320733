import React from 'react';
import { withRouter } from 'react-router'
import {
    Button,
    Steps,
    Input,
    Progress,
    Result,
    Modal,
    Typography,
    Select,
    Tabs,
    message,
} from 'antd';
import './Fidelizar.css';
import PageLayout from '../../components/layout/PageLayout';
import CPFInput from '../../components/masks/cpf';
import API from '../../components/api/api';
import { currentUser, getConvenio, getUserType } from '../../components/auth/auth-provider';
import CurrencyInput from 'react-currency-input';
import mensagem from '../../components/messages/message';
import TelefoneInput from '../../components/masks/telefone';
import { getColor } from '../../components/layout/themes/LayoutCSS';
import dayjs from 'dayjs';
import { HTML_COMPROVANTE } from '../../components/funcs/constants';
import { formatCPF } from '../../components/funcs/utils';
import administradorUsuarioDB from '../../dataManager/dtmAdministradorUsuario';
import administradorDB from '../../dataManager/dtmAdministrador';

const { Text } = Typography;
const { Step } = Steps;
const api = new API();
let token = undefined;
let objectCounter = undefined;
const key = 'updatable';
const { Option } = Select;
const { TabPane } = Tabs;


class Fidelizar extends React.Component {
    state = {
        disableInput: false,
        countDownStr: 'Finalizar (15 seg)',
        showResgate: false,
        resgate: false,
        showNewParticipant: false,
        step: 0,
        resultType: 'success',
        resultText: '',
        resultTextSubTitle: '',
        progress: 0,
        totalPontosBeneficiario: 0,
        resgatesDisponiveis: [],
        pontosResgatados: 0,
        pontosResgatadosText: '',
        loading: true,
        valor: 'R$ 0,00',
        nrDocumento: '',
        valorEmCentavos: 0,
        cssBtnCad: 'btnCad',
        comprovante: undefined,
        beneficiario: {
            nome: '',
            sobrenome: '',
            cpf: '',
            email: '',
            endereco: {
                logradouro: '',
                bairro: '',
                cidade: '',
                uf: '',
                telefone: '',
            },
        },
        premiacao: {
            fidelidade: {
                valor: 'R$ 0,00',
                pontos: 0,
            }
        }
    }

    constructor(props) {
        super(props);
        this.onCpfChange = this.onCpfChange.bind(this);
        this.onNomeChange = this.onNomeChange.bind(this);
        this.onTelefoneChange = this.onTelefoneChange.bind(this);
        this.onEnderecoChange = this.onEnderecoChange.bind(this);
        this.onBairroChange = this.onBairroChange.bind(this);
        this.onCidadeChange = this.onCidadeChange.bind(this);
        this.onUFChange = this.onUFChange.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onNrDocumentoChange = this.onNrDocumentoChange.bind(this);

        this.changeValue = this.changeValue.bind(this);
        this.prior = this.prior.bind(this);
        this.next = this.next.bind(this);
        this.finalize = this.finalize.bind(this);
        this.stepOne = this.stepOne.bind(this);
        this.stepTwo = this.stepTwo.bind(this);
        this.startCounter = this.startCounter.bind(this);
        this.exibirResgate = this.exibirResgate.bind(this);

        this.pontuar = this.pontuar.bind(this);
        this.newParticipant = this.newParticipant.bind(this);
        this.cancelPartipant = this.cancelPartipant.bind(this);
        this.resgatar = this.resgatar.bind(this);
        this.premioSelectChange = this.premioSelectChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.enviarComprovante = this.enviarComprovante.bind(this);
        this.imprimirComprovante = this.imprimirComprovante.bind(this);
    }

    async componentDidMount() {
        const convenio = getConvenio();
        const premiacao = convenio.administrador.premiacao ? convenio.administrador.premiacao : this.state.premiacao;
        this.setState({ loading: false, premiacao });
    }

    onCpfChange(e) {
        const beneficiario = this.state.beneficiario;
        beneficiario.cpf = e.target.value.replace(/[^\d]+/g, '');
        this.setState({ beneficiario });
    }

    onNomeChange(e) {
        const beneficiario = this.state.beneficiario;
        const fullName = e.target.value;
        beneficiario.nome = fullName;
        this.setState({ beneficiario });
    }

    onTelefoneChange(e) {
        const beneficiario = this.state.beneficiario;
        const telefone = e.target.value;
        beneficiario.endereco.telefone = telefone;
        this.setState({ beneficiario });
    }

    onEnderecoChange(e) {
        const beneficiario = this.state.beneficiario;
        const endereco = e.target.value;
        beneficiario.endereco.logradouro = endereco;
        this.setState({ beneficiario });
    }

    onBairroChange(e) {
        const beneficiario = this.state.beneficiario;
        const bairro = e.target.value;
        beneficiario.endereco.bairro = bairro;
        this.setState({ beneficiario });
    }

    onCidadeChange(e) {
        const beneficiario = this.state.beneficiario;
        const cidade = e.target.value;
        beneficiario.endereco.cidade = cidade;
        this.setState({ beneficiario });
    }

    onUFChange(e) {
        const beneficiario = this.state.beneficiario;
        const uf = e.target.value.toUpperCase();
        beneficiario.endereco.uf = uf;
        this.setState({ beneficiario });
    }

    onEmailChange(e) {
        const beneficiario = this.state.beneficiario;
        const email = e.target.value;
        beneficiario.email = email;
        this.setState({ beneficiario });
    }

    onNrDocumentoChange(e) {
        const nrDocumento = e.target.value;
        this.setState({ nrDocumento });
    }

    changeValue(value) {
        const valorEmCentavos = Number(value.replace('.', '').replace('R$ ', '').replace(/[^\d.-]/g, ''));
        this.setState({ valor: value, valorEmCentavos });
    }

    premioSelectChange(value) {
        const premio = this.state.resgatesDisponiveis.filter((item) => {
            return item.key === value
        });

        const pontosResgatados = Number(value);
        const pontosResgatadosText = premio[0].props.children;
        this.setState({ pontosResgatados, pontosResgatadosText });
    }

    onKeyDown(event) {
        if (event.key === 'Enter') {
            this.next();
        }
    }

    async stepOne() {

        if (!this.state.beneficiario.cpf && !this.state.beneficiario.endereco.telefone) {
            message.warning({ content: 'Informe o CPF, CNPJ ou Celular', key });
            return;
        }

        this.setState({ loading: true });

        let get = undefined;
        const convenio = getConvenio();
        try {
            get = await api.getToken(convenio.key);
        } catch (err) {
            message.error({ content: err.message ? err.message : err.error, key });
            this.setState({ loading: false });
            return;
        }

        if (get && get.token) {
            token = get.token;

            const findByCelular =
                this.state.beneficiario.endereco.telefone && !this.state.beneficiario.cpf ? true : false;

            const data = findByCelular ? this.state.beneficiario.endereco.telefone : this.state.beneficiario.cpf;

            api.getPoints(data, findByCelular, token)
                .then(res => {
                    const step = this.state.step + 1;
                    const beneficiario = this.state.beneficiario;

                    beneficiario.nome = `${res.participant.firstName} ${res.participant.lastName}`;
                    beneficiario.userExists = true;
                    beneficiario.cpf = res.participant.doc;
                    beneficiario.email = res.participant.email;
                    beneficiario.endereco.telefone = res.participant.fullAddress.phoneNumber;

                    const resgatesDisponiveis = [];

                    if (res.balance.canRescue) {
                        for (let index = 0; index < res.awards.availableItems.length; index++) {
                            const resgateDisponivel = res.awards.availableItems[index];
                            const descricao = `(${resgateDisponivel.score} pts) - ${resgateDisponivel.award}`;
                            resgatesDisponiveis.push(<Option key={resgateDisponivel.score}>{descricao}</Option>);
                        }
                    }

                    this.setState({
                        step,
                        beneficiario,
                        progress: res.balance.percentage,
                        totalPontosBeneficiario: res.balance.score,
                        resgatesDisponiveis,
                        loading: false,
                        cssBtnCad: 'btnCad2',
                    });

                    document.getElementById('txtValor').focus();
                })
                .catch(err => {
                    // Quando o usuário não existir, ele também poderá 
                    // avançar para a próxima página
                    if (err.userExists === false) {
                        const beneficiario = this.state.beneficiario;
                        beneficiario.nome = '';
                        beneficiario.sobrenome = '';

                        const progress = 0;
                        const totalPontosBeneficiario = 0;
                        beneficiario.userExists = err.userExists;
                        const step = this.state.step + 1;
                        this.setState({ step, beneficiario, progress, totalPontosBeneficiario, cssBtnCad: 'btnCad2' });
                        document.getElementById('txtNome').focus();
                    } else {
                        message.error({ content: err.message, key });
                    }

                    this.setState({ loading: false });
                });
        }
    }

    async stepTwo() {

        if (this.state.valorEmCentavos === 0) {
            message.warning({ content: 'Informe o valor da compra', key });
            return;
        }

        if (this.state.progress >= 100) {
            let isOk = await mensagem.confirmar('Seu cliente já possui pontos para resgate. Você tem certeza que deseja pontuar ao invés de lhe premiar?');
            if (!isOk) {
                return;
            }
        }

        // Quando não existir o usuário, salvo ele primeiro
        if (this.state.beneficiario.userExists === false) {
            // this.setState({ loading: true });
            this.setState({ showNewParticipant: true });
            return;
        }

        this.pontuar();
    }

    prior() {
        const step = this.state.step - 1;

        if (step < 0) {
            return;
        }

        if (step === 0) this.finalize();

        const cssBtnCad = step === 0 ? 'btnCad' : 'btnCad2';
        this.setState({ step, cssBtnCad });
    }

    async next() {
        const step = this.state.step + 1;
        if (step === 1) {
            this.stepOne();
        } else if (step === 2) {
            this.stepTwo();
        }
    }

    finalize() {

        const beneficiario = {
            nome: '',
            sobrenome: '',
            cpf: '',
            endereco: {
                logradouro: '',
                bairro: '',
                cidade: '',
                uf: '',
                telefone: '',
            },
        };

        clearInterval(objectCounter);

        this.setState({
            step: 0,
            beneficiario,
            valorEmCentavos: 0,
            progress: 0,
            loading: false,
            resgate: false,
            valor: `R$ 0,00`,
            nrDocumento: '',
            cssBtnCad: 'btnCad'
        });
    }

    startCounter() {
        this.setState({ countDownStr: `Finalizar (15 seg)` });
        const a_this = this;
        var counter = 15;
        objectCounter = setInterval(function () {
            counter--;
            a_this.setState({ countDownStr: `Finalizar (${counter} seg)` });
            if (counter <= 0) {
                clearInterval(objectCounter);
                a_this.finalize();
            }
        }, 1000);
    }

    exibirResgate(showResgate) {
        if (showResgate) {
            const premiacao = this.state.premiacao;
            premiacao.fidelidade.pontoResgate = premiacao.fidelidade.pontos;
            this.setState({ premiacao });
        }
        this.setState({ showResgate });
    }

    enviarComprovante(pontos) {
        const { nome, cpf, endereco, email } = this.state.beneficiario;
        const { valor } = this.state;

        const comprovante = {
            pontos,
            beneficiario: {
                nome,
                cpf: formatCPF(cpf),
                telefone: endereco.telefone
            },
            convenioNome: getConvenio().nomeFantasia,
            date: new Date(),
            valor: valor.split('R$ ')[1]
        };

        this.setState({ comprovante });

        if (email === '' /*&& endereco.telefone === ''*/) return;

        let contact;
        let sendByEmail;

        if (email !== '') {
            contact = email;
            sendByEmail = true;
        } /*else if (endereco.telefone !== '') {
            contact = endereco.telefone;
            sendByEmail = false;
        }
        
        Todo código comentado é para quando habilitar o envio por sms
        */

        api.sendReceipt(comprovante, contact, sendByEmail, token);
    }

    imprimirComprovante() {
        const {
            beneficiario,
            convenioNome,
            date,
            pontos,
            valor
        } = this.state.comprovante;

        const {
            nome,
            cpf,
            telefone
        } = beneficiario;

        const dateObj = dayjs(date);
        let htmlTemplate = HTML_COMPROVANTE;

        htmlTemplate = htmlTemplate
            .replace('{data}', dateObj.format('DD/MM/YYYY'))
            .replace('{hora}', `${dateObj.hour()}:${dateObj.minute()}:${dateObj.second()}`)
            .replace('{beneficiario}', nome)
            .replace('{cpf}', cpf !== '' ? cpf : '-')
            .replace('{telefone}', telefone !== '' ? telefone : '-')
            .replace('{convenio}', convenioNome)
            .replace('{valor}', valor)
            .replace('{pontos}', pontos);

        const wnd = window.open('data:text/html;charset=utf-8,', '_blank');
        wnd.document.write(htmlTemplate);

        setTimeout(() => wnd.print(), 500);
    }

    async pontuar() {
        const step = this.state.step + 1;
        this.setState({ loading: true });

        const findByCelular =
            this.state.beneficiario.endereco.telefone && !this.state.beneficiario.cpf ? true : false;

        const user = currentUser();
        const tipo = await getUserType();
        let operador = undefined;

        if (tipo === 'AdministradorUsuario') {
            const administradorUsuario = await administradorUsuarioDB.getByEmail(user.email);
            operador = `${administradorUsuario[0].nome} ${administradorUsuario[0].sobrenome}`;
        } else if (tipo === 'Administrador') {
            const administrador = await administradorDB.getByEmail(user.email);
            operador = `${administrador[0].nomeFantasia}`;
        }

        api.savePoints(this.state.beneficiario, this.state.valorEmCentavos, this.state.nrDocumento, findByCelular, operador, token)
            .then(res => {
                const id = res.id;
                const pontos = res.score;

                const resultType = 'success';
                const resultText = 'Participante pontuou com sucesso!';
                const resultTextSubTitle = `Id da transação: ${id}  -  Pontuação alcançada: ${pontos}`;

                this.startCounter();
                this.enviarComprovante(res.score);

                this.setState({ resultType, resultText, resultTextSubTitle, step });
            })
            .catch(err => {
                const resultType = 'warning';
                const resultText = 'Não foi possível pontuar!';
                const resultTextSubTitle = err.message;
                this.startCounter();
                this.setState({ resultType, resultText, resultTextSubTitle, step });
            });
    }

    async newParticipant() {

        if (!this.state.beneficiario.nome) {
            message.warning({ content: 'Informe o nome do participante', key });
            return;
        }

        if (!this.state.beneficiario.cpf && !this.state.beneficiario.endereco.telefone) {
            message.warning({ content: 'Informe o CPF, CNPJ ou Celular', key });
            return;
        }

        this.setState({ loading: true });
        const fullName = this.state.beneficiario.nome.toLocaleLowerCase().replace(/\b\w/g, l => l.toUpperCase());

        const beneficiario = {};
        beneficiario.firstName = fullName.split(' ').slice(0, -1).join(' ');
        beneficiario.lastName = fullName.split(' ').slice(-1).join(' ');
        beneficiario.cpf = this.state.beneficiario.cpf;

        beneficiario.address = this.state.beneficiario.endereco.logradouro;
        beneficiario.district = this.state.beneficiario.endereco.bairro;
        beneficiario.city = this.state.beneficiario.endereco.cidade
        beneficiario.state = this.state.beneficiario.endereco.uf;
        beneficiario.phoneNumber = this.state.beneficiario.endereco.telefone;
        beneficiario.email = this.state.beneficiario.email;

        try {
            message.info({ content: 'Salvando o novo participante...', key });

            const findByCelular =
                this.state.beneficiario.endereco.telefone && !this.state.beneficiario.cpf ? true : false;

            await api.savePerson(beneficiario, findByCelular, token);
            this.setState({ showNewParticipant: false });
            message.info({ content: 'Perfeito! Participante cadastrado', key });
            this.pontuar();
        } catch (err) {
            message.error({ content: err.message, key });
            this.state.beneficiario.nome = fullName;
            this.setState({ loading: false });
            return;
        }
    }

    async cancelPartipant() {
        this.setState({ showNewParticipant: false });
    }

    async resgatar() {

        if (this.state.pontosResgatados === 0) {
            message.error({ content: 'Escolha o prêmio disponível', key });
            return;
        }

        this.setState({ showResgate: false, loading: true, resgate: true });

        const convenio = getConvenio();
        const get = await api.getToken(convenio.key);
        if (get && get.token) {
            token = get.token;

            const findByCelular =
                this.state.beneficiario.endereco.telefone && !this.state.beneficiario.cpf ? true : false;

            api.rescuePoints(
                this.state.beneficiario,
                this.state.pontosResgatados,
                findByCelular,
                get.token)
                .then(res => {
                    const resultType = 'success';
                    const resultText = 'Participante resgatou seus pontos com sucesso!';
                    const resultTextSubTitle = ``;
                    this.startCounter();
                    this.setState({
                        resultType,
                        resultText,
                        resultTextSubTitle,
                        pontosResgatados: 0,
                        pontosResgatadosText: '',
                        step: 2
                    });

                })
                .catch(err => {
                    const resultType = 'warning';
                    const resultText = 'Não foi possível fazer o restate!';
                    const resultTextSubTitle = err.message;
                    this.startCounter();
                    this.setState({ resultType, resultText, resultTextSubTitle, step: 2 });
                })
        }

    }

    render() {
        const pontosStr = this.state.totalPontosBeneficiario > 1 ? `pontos` : `ponto`;
        return (
            <div className="divTable">
                <PageLayout selectItem={'fidelizar'}>
                    <div className="divParent">
                        <div className='containerSteps'>
                            <Steps className="steps" current={this.state.step}>
                                <Step title="Identificação" description="Localizar" />
                                <Step title="Participante" description="Dados Pessoais" />
                                <Step title="Finalização" description="Conclusão" />
                            </Steps>
                        </div>

                        <div className="divMiddle">
                            {this.state.step === 0 && (
                                <div>
                                    <CPFInput
                                        id="txtCPFStepOne"
                                        className="inputPoint inputCPFStepOne"
                                        value={this.state.beneficiario.cpf}
                                        onChange={this.onCpfChange}
                                        onKeyDown={this.onKeyDown}
                                    ></CPFInput>

                                    <p className="txtCelularLocalizacao">ou Celular</p>
                                    <TelefoneInput
                                        className="inputPoint inputCPF"
                                        value={this.state.beneficiario.endereco.telefone}
                                        onChange={this.onTelefoneChange}
                                        disabled={false}
                                    ></TelefoneInput>
                                </div>
                            )}

                            {this.state.step === 1 && (
                                <div className="divBeneficiario">
                                    <div className="left">
                                        <label>Nome</label>
                                        <Input
                                            id="txtNome"
                                            placeholder={'Nome'}
                                            value={this.state.beneficiario.nome}
                                            onChange={this.onNomeChange}
                                            className="inputLoyalty"
                                            disabled={this.state.beneficiario.userExists}
                                        />

                                        <label>CPF/CNPJ</label>
                                        <CPFInput
                                            className="inputPoint inputCPF"
                                            value={this.state.beneficiario.cpf}
                                            disabled={true}
                                        />

                                        <label>Valor</label>
                                        <CurrencyInput
                                            id="txtValor"
                                            type="tel"
                                            className="inputPoint inputCad"
                                            prefix="R$ "
                                            decimalSeparator=","
                                            thousandSeparator="."
                                            value={this.state.valor}
                                            onChange={this.changeValue} />

                                        <label>Número do Documento</label>
                                        <Input
                                            id="txtNrDocumento"
                                            placeholder={'Número do Documento'}
                                            value={this.state.nrDocumento}
                                            onChange={this.onNrDocumentoChange}
                                            className="inputLoyalty"
                                        />
                                    </div>

                                    <div className="rigth">
                                        <div className="vertical-center">
                                            <Progress
                                                type="circle"
                                                percent={this.state.progress}
                                                strokeColor={getColor()}
                                            />

                                            <div className='labelsDiv'>
                                                <label className="labelPontos">
                                                    {`de ${this.state.premiacao.fidelidade.premios ? this.state.premiacao.fidelidade.premios[0].pontos : '--'} pontos (mín)`}
                                                </label>

                                                <label className="labelValor">
                                                    {`1 ponto a cada ${this.state.premiacao.fidelidade.valor}`}
                                                </label>

                                                <label className="labelPontosBeneficiario">
                                                    {`Participante possui ${this.state.totalPontosBeneficiario} ${pontosStr}`}
                                                </label>
                                            </div>

                                            {this.state.progress >= 100 && (
                                                <Button className="btnResgate" type="primary" onClick={() => this.exibirResgate(true)} disabled={this.state.loading || this.state.step <= 0}>
                                                    Ei, psiu! Seu cliente já pode resgatar
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {(this.state.step > 0 && this.state.step < 2) && (
                                <Button className={this.state.cssBtnCad} onClick={() => this.prior()} disabled={this.state.loading || this.state.step <= 0}>
                                    Anterior
                                </Button>
                            )}


                            {(this.state.step === 0 || this.state.step === 1) && (
                                <Button className={this.state.cssBtnCad} type="primary" onClick={() => this.next()} disabled={this.state.loading} loading={this.state.loading}>
                                    Próximo
                                </Button>
                            )}

                            {(this.state.step === 2) && (
                                <Result
                                    status={this.state.resultType}
                                    title={this.state.resultText}
                                    subTitle={this.state.resultTextSubTitle}
                                    extra={[
                                        <Button type="primary" key="console" onClick={() => this.finalize()} >
                                            {this.state.countDownStr}
                                        </Button>,
                                        (!this.state.resgate && (
                                            <Button type='primary' onClick={this.imprimirComprovante}>
                                                Imprimir comprovante
                                            </Button>
                                        ))
                                    ]}
                                />
                            )}

                            <Modal
                                title="Novo Participante 🙃"
                                visible={this.state.showNewParticipant}
                                onOk={this.newParticipant}
                                okText='Salvar'
                                onCancel={() => this.cancelPartipant()}
                                loading={this.state.loading}
                                destroyOnClose={true}
                            >

                                <Tabs defaultActiveKey="geral">

                                    <TabPane tab="Geral" key="geral">

                                        <p>Nome</p>
                                        <Input
                                            id="txtNome"
                                            placeholder={'Nome'}
                                            value={this.state.beneficiario.nome}
                                            onChange={this.onNomeChange}
                                            className="inputLoyalty "
                                        />

                                        <p>CPF/CNPJ</p>
                                        <CPFInput
                                            className="inputPoint inputCPF"
                                            value={this.state.beneficiario.cpf}
                                            disabled={false}
                                            onChange={this.onCpfChange}
                                            onKeyDown={this.onKeyDown}
                                        ></CPFInput>

                                        <p>Celular</p>
                                        <TelefoneInput
                                            className="inputPoint inputCPF"
                                            value={this.state.beneficiario.endereco.telefone}
                                            onChange={this.onTelefoneChange}
                                            disabled={false}
                                        ></TelefoneInput>

                                        <p>Email</p>
                                        <Input
                                            className='inputPoint inputCPF'
                                            placeholder='Informe o Email'
                                            value={this.state.beneficiario.email}
                                            onChange={this.onEmailChange}
                                        />
                                    </TabPane>

                                    <TabPane tab="Endereço" key="endereco">

                                        <p>Endereço</p>
                                        <Input
                                            id="txtEndereco"
                                            value={this.state.beneficiario.endereco.logradouro}
                                            onChange={this.onEnderecoChange}
                                            className="inputLoyalty "
                                        />

                                        <p>Bairro</p>
                                        <Input
                                            id="txtBairro"
                                            value={this.state.beneficiario.endereco.bairro}
                                            onChange={this.onBairroChange}
                                            className="inputLoyalty "
                                        />

                                        <p>Cidade</p>
                                        <Input
                                            id="txtCidade"
                                            value={this.state.beneficiario.endereco.cidade}
                                            onChange={this.onCidadeChange}
                                            className="inputLoyalty "
                                        />


                                        <p>UF</p>
                                        <Input
                                            id="UF"
                                            value={this.state.beneficiario.endereco.uf}
                                            maxLength={2}
                                            onChange={this.onUFChange}
                                            className="inputLoyalty "
                                        />
                                    </TabPane>
                                </Tabs>
                            </Modal>

                            <Modal
                                title="Resgate 🎁"
                                visible={this.state.showResgate}
                                onOk={this.resgatar}
                                okText='Resgatar'
                                onCancel={() => this.exibirResgate(false)}
                            >
                                <Text type="secondary">Que legal! Seu cliente ficará feliz por ser recompensado</Text>
                                <Select
                                    mode="single"
                                    style={{ width: "100%" }}
                                    placeholder="Selecione o prêmio"
                                    onChange={this.premioSelectChange}
                                    value={this.state.pontosResgatadosText}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }

                                >
                                    {this.state.resgatesDisponiveis}
                                </Select>
                            </Modal>
                        </div >
                    </div >
                </PageLayout >
            </div >
        );
    }
}

export default withRouter(Fidelizar);