import React, { Component } from "react";
import { withRouter } from 'react-router'
import './Dashboard.css';
import PageLayout from '../../components/layout/PageLayout';
import { Line } from "react-chartjs-2";
import moment from "moment";
import { getConvenio } from "../../components/auth/auth-provider";
import { Spin, Modal, message } from 'antd';
import { buildChart } from "../relatorios/fidelidade/FidelidadeF";
import beneficiarioDB from "../../dataManager/dtmBeneficiario";
import usuarioDB from "../../dataManager/dtmUsuario";
import AlterarSenhaCRUD from "../../components/cruds/alterarSenha/AlterarSenhaCRUD"
import { currentUser } from "../../components/auth/auth-provider";
import { updatePassword } from "../perfil/Funcs-Perfil";
import GraficoFidelidade from "../../components/Dashboard/GraficoFidelidade";


const VERDE = '#32a852';
const LARANJA = '#ebb134';
const ROXO = '#a234eb';
const VERMELHO = '#D9534F';

class Dashboard extends Component {

    state = {
        loading: false,
        data: {
            labels: [],
            datasets: []
        },
        valores: {
            pontos: 'Calculando...',
            baixados: 'Calculando...',
            novosClientes: 'Calculando...',
            valor: 'Calculando...'
        }
    }

    constructor(props) {
        super(props);
        this.buildChart = this.buildChart.bind(this);
    }

    async componentDidMount() {

        //this.buildChart();
    }

    async buildChart() {
        const conveniosIds = [];
        const isAdm = true;
        const convenio = getConvenio();

        if (isAdm) {
            conveniosIds.push({ key: convenio.key });
        }

        let iniDate = new Date();
        iniDate.setDate(iniDate.getDate() - 15);
        iniDate = new Date(iniDate.getFullYear(), iniDate.getMonth(), iniDate.getDate(), 0, 0, 0);

        let endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);

        iniDate = moment(iniDate);
        endDate = moment(endDate);

        const dataSet = await buildChart(
            iniDate,
            endDate,
            convenio.ownerId,
            conveniosIds,
            true
        );

        const data = {
            labels: [],
            datasets: [
                {
                    label: "Pontos",
                    data: [],
                    fill: true,
                    backgroundColor: "#f0e3c4",
                    borderColor: LARANJA,
                },
                {
                    label: "Resgates",
                    data: [],
                    fill: false,
                    hidden: true,
                    borderColor: VERDE,
                    backgroundColor: "#99b3a0",
                },
                {
                    label: "Novos Clientes",
                    data: [],
                    fill: false,
                    hidden: true,
                    borderColor: ROXO,
                    backgroundColor: "#cba9e2",
                },
                {
                    label: "Valores Movimentados",
                    data: [],
                    fill: false,
                    hidden: false,
                    borderColor: VERMELHO,
                    backgroundColor: "#f3a19e",
                }
            ]
        };

        let valores = {
            pontos: 0,
            baixados: 0,
            novosClientes: 0,
            valor: 0,
        }

        dataSet.chartData.forEach((item) => {
            valores.pontos += item.pontos;
            valores.baixados += item.baixados;
            valores.novosClientes += item.novosClientes;
            valores.valor += item.valueInCents;

            data.datasets[0].data.push(item.pontos);
            data.datasets[1].data.push(item.baixados);
            data.datasets[2].data.push(item.novosClientes);
            data.datasets[3].data.push(item.valueInCents / 100);

            const date = new Date(item.data);
            data.labels.push(date.getDate() + "/" + (date.getMonth() + 1))
        });

        valores.valor = beneficiarioDB.formatarMoeda(valores.valor / 100);
        this.setState({ data, valores, loading: false });
    }

    render() {
        return (
            <div>
                <AlterarSenha />

                <PageLayout selectItem={'dashboard'}>

                    <GraficoFidelidade></GraficoFidelidade>




                    {/* <div className="divDashLayout">
                        {(this.state.loading === true) && (
                            <div className="divDashSpin">
                                <Spin size="large" />
                            </div>
                        )}

                        <label className='chartTitle'>Movimentação nos últimos 15 dias</label>

                        <div className="row divCardsValores">

                            <div className="col-lg-3 col-sm-6">
                                <div className="card-box bg-orange">
                                    <div className="inner">
                                        <h3> {this.state.valores.pontos} </h3>
                                        <p> Pontos </p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa fa-arrow-up " aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-sm-6">
                                <div className="card-box bg-green">
                                    <div className="inner">
                                        <h3> {this.state.valores.baixados} </h3>
                                        <p>Resgates</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa fa-arrow-down " aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-sm-6">
                                <div className="card-box bg-purple">
                                    <div className="inner">
                                        <h3> {this.state.valores.novosClientes} </h3>
                                        <p> Novos Clientes </p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa fa-user" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-sm-6">
                                <div className="card-box bg-red">
                                    <div className="inner">
                                        <h3> {this.state.valores.valor} </h3>
                                        <p>Valores Movimentados</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa fa-calendar" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div style={{
                            position: "relative",
                            margin: "auto",
                            width: "100%",
                            height: '21rem'
                        }}>
                            <Line className="chartPontosResgates"
                                data={this.state.data}
                                //height="5px"
                                options={{
                                    maintainAspectRatio: false,
                                    responsive: true,

                                    legend: {
                                        display: true,
                                        position: 'bottom',
                                    },

                                    tooltips: {
                                        callbacks: {
                                            label: function (tooltipItem, data) {
                                                let label = data.datasets[tooltipItem.datasetIndex].label;
                                                let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                                // value = administradorDB.formatarMoeda(value);
                                                return ' ' + label + ': ' + value
                                            }
                                        }
                                    },
                                    scales: {
                                        yAxes: [
                                            {
                                                ticks: {
                                                    beginAtZero: true,
                                                }

                                            }
                                        ]
                                    },
                                    layout: {
                                        padding: {
                                            top: 20,
                                            right: 40,
                                            left: 40,
                                            bottom: 20,
                                        }
                                    },
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            anchor: "end",
                                            align: "top",
                                            formatter: function (value, context) {
                                                if (value === undefined) {
                                                    return 0
                                                } else {
                                                    if (context.dataset.label === 'Valores Movimentados') {
                                                        return value = `${beneficiarioDB.formatarMoeda(value)}`;
                                                    } else if (context.dataset.label === 'Novos Clientes') {
                                                        return value;
                                                    } else {
                                                        return value = `${value} pts`;
                                                    }
                                                }
                                            }
                                        },
                                    },
                                }}
                            />
                        </div>


                    </div> */}
                </PageLayout>
            </div >
        );
    }
}

export default withRouter(Dashboard);

class AlterarSenha extends Component {
    state = {
        visible: false,
        confirmLoading: false,
        usuario: undefined
    }

    constructor(props) {
        super(props);
        this.handleOk = this.handleOk.bind(this);
    }

    async componentDidMount() {
        let visible = false;
        let usuario = undefined;

        const user = currentUser();
        usuario = await usuarioDB.getByUserId(user.uid);
        visible = !usuario || !usuario.lastPasswordUpdate;

        this.setState({ visible, usuario });
    }

    async handleOk(password) {
        const failMessage = 'Erro ao tentar salvar a nova senha. Tente novamente.'
        const isOk = await updatePassword(password);
        if (!isOk) {
            message.error(failMessage);
            return;
        }

        const usuario = this.state.usuario;
        usuario.lastPasswordUpdate = new Date();
        const res = await usuarioDB.update(usuario.key, usuario);

        if (!res.updated) {
            message.error(failMessage);
            return;
        }

        this.setState({ visible: false });
    }

    render() {
        return (
            <Modal
                title='Nova senha necessária para continuar'
                visible={this.state.visible}
                destroyOnClose={true}
                confirmLoading={this.state.confirmLoading}
                centered={true}
                onOk={this.handleOk}
                footer={null}
                closable={false}>

                <AlterarSenhaCRUD handleOk={this.handleOk}></AlterarSenhaCRUD>
            </Modal>
        )
    }
}