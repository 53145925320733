import ls from 'local-storage'
import DataManager from '../../dataManager/dtm';
import { fbDatabase } from '../../firebase/firebase';
import vertteStorage from '../localstorage/localstorage';


export function authenticate(user, convenio) {
    ls.set('loyol-user', user);
    ls.set('loyol-convenio', convenio);
    ls.set('login', true);
}

export function logout() {
    ls.set('loyol-user', null);
    ls.set('loyol-user-type', null);
    ls.set('loyol-convenio', null);
    vertteStorage.clearAll();
}

export function getConvenio() {
    let convenio = ls.get('loyol-convenio');
    return convenio ? convenio : undefined;
}

export function getConvenioAdministrador() {
    let convenios = ls.get('loyol-convenios');
    return convenios ?? undefined;
}

export function changeConvenio(convenio) {
    ls.set('loyol-convenio', convenio);
}

export function currentUser() {
    let user = ls.get('loyol-user');
    return user;
}

export async function getUserType() {
    const dtm = new DataManager();
    const { uid } = ls.get('loyol-user');

    const query = fbDatabase
        .collection('usuarios')
        .where('uid', '==', uid);

    const snapshot = await dtm.getQueryData(query, true);
    return snapshot.docs[0].data().tipo;
}

export function isLoggedIn() {
    const user = currentUser();
    return user !== null && user !== undefined;
}

export function getOwnerId() {
    // Se precisar, pegar o objeto do convênio e verificar
}
