import React from 'react'
import { withRouter } from 'react-router'
import { Layout, Menu, Dropdown, Divider, Avatar, Button, Tooltip, Select } from 'antd';
import { changeConvenio, currentUser, getConvenio, getConvenioAdministrador, getUserType, logout } from '../auth/auth-provider';
import { getLogoClass } from './themes/LayoutCSS';
import vertteStorage from '../localstorage/localstorage';
import history from '../../history';
import './PageLayout.css';
import './themes/LayoutCSS';


const { Header, Content } = Layout;
const { SubMenu } = Menu;
const { Option } = Select;


class PageLayout extends React.Component {

    state = {
        collapsed: false,
        friendlyName: '',
        classLogo: 'labelLogoShow',
        classLabelFooter: 'footerVertteLabelNormal',
        photoURL: '',
        userType: '4C Admin',
        logoClass: '',
        permissoes: {
            clientes: true,
        }
    };

    constructor(props) {
        super(props);
        this.onMenuClick = this.onMenuClick.bind(this);
    }

    async componentDidMount() {
        this.setState({ logoClass: getLogoClass() });

        const user = currentUser();
        const convenio = getConvenio();

        const displayName = user.email ?
            user.email.substring(0, user.email.indexOf("@")) :
            convenio.nomeFantasia.toLocaleLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });

        let friendlyName = user.displayName ? `${user.displayName}` : `${displayName}`;

        if (user.displayName) {
            friendlyName = friendlyName.split(' ')[0].toLocaleLowerCase().replace(/\b\w/g, l => l.toUpperCase());
        }

        this.setState({
            friendlyName,
            photoURL: user.photoURL ? user.photoURL : 'https://storage.googleapis.com/bluve-bucket/base_img_avatar.png',
        });

        // Verificar se o usuário já estava logado
        if (!JSON.parse(vertteStorage.get('login'))) {
            logout();
            history.push(`/login`);
            history.go();
        }
    }

    onCollapse = collapsed => {
        let classLogo = 'labelLogoShow';
        let classLabelFooter = 'footerVertteLabelNormal';
        if (collapsed) {
            classLogo = 'labelLogoHidden';
            classLabelFooter = 'footerVertteLabelCollapsed'
        }
        this.setState({ classLabelFooter, classLogo, collapsed });
    };

    onMenuClick(item) {
        // Para que o usuário veja o item ficar azul antes do redirect. 
        // Não remover
        setTimeout(() => {
            history.push(`/${item.key}`);
            history.go();
        }, 300);
    }

    handleMenuClick(e) {
        console.clear();
        console.log(e);
        if (e.key === 'menuSair') {
            logout();
            history.push('/login');
            history.go();
        }
    }

    render() {
        const menu = (
            <Profile userType={this.state.userType}></Profile>
        );

        return (
            <React.Fragment>
                <Layout>
                    <Layout style={{ height: "100vh" }}>
                        <Header style={{
                            padding: 0,
                            display: 'flex',
                            height: '6.25rem',
                            backgroundColor: '#143361'
                        }}>

                            <div className="logo">
                                <div className={this.state.logoClass}>
                                    <img alt="Moub"></img>
                                </div>
                            </div>

                            <Menu
                                defaultSelectedKeys={[this.props.selectItem]}
                                defaultOpenKeys={[this.props.openMenu]}
                                onClick={this.onMenuClick}
                                theme="dark"
                                mode="horizontal"
                                style={{
                                    fontWeight: 600,
                                    fontSize: '1rem',
                                    backgroundColor: 'transparent',
                                    lineHeight: '6.25rem',
                                    width: '70%',
                                    marginRight: 'auto'
                                }}>

                                <Menu.Item key="dashboard">
                                    <span>Dashboard</span>
                                </Menu.Item>

                                <Menu.Item key="fidelizar">
                                    <span>Fidelizar</span>
                                </Menu.Item>

                                <Menu.Item key="beneficiarios">
                                    <span>Beneficiários</span>
                                </Menu.Item>

                                <SubMenu
                                    key="estornar"
                                    title={
                                        <span>
                                            <span>Estornar</span>
                                        </span>
                                    }>

                                    <Menu.Item key="estornar/pontos">
                                        <span>Pontos</span>
                                    </Menu.Item>

                                    <Menu.Item key="estornar/resgates">
                                        <span>Resgates</span>
                                    </Menu.Item>

                                </SubMenu>

                                <SubMenu
                                    key="relatorios"
                                    title={
                                        <span>
                                            <span>Relatórios</span>
                                        </span>
                                    }>

                                    <Menu.Item key="relatorios/fidelidade">
                                        <span>Fidelidade</span>
                                    </Menu.Item>

                                    <Menu.Item key="relatorios/extrato">
                                        <span>Extrato</span>
                                    </Menu.Item>
                                </SubMenu>
                            </Menu>

                            <Dropdown overlay={menu} trigger={['click']}>
                                <div>
                                    <Avatar className="profileAvatar" size={44} src={this.state.photoURL}
                                        style={{ margin: '1.75rem 1.25rem' }} />
                                </div>
                            </Dropdown>
                        </Header>

                        <Content style={{ margin: '0.625rem' }}>
                            <div style={{ background: '#fff', minHeight: '100%', padding: '1.25rem', borderRadius: '0.75rem' }}>
                                {this.props.children}
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </React.Fragment>
        )
    }
}

class Profile extends React.Component {

    state = {
        photoUrl: '',
        userType: '',
        userName: '',
        userEmail: '',
        convenioKey: '',
        convenios: undefined,
        administrador: false
    }

    convenioOptions = [];

    async componentDidMount() {
        if (await getUserType() === 'Administrador') {
            const convenios = getConvenioAdministrador();

            if (!convenios) return;

            for (const item of convenios) {
                this.convenioOptions.push(
                    <Option key={item.key} value={item.key}>
                        {item.nomeFantasia}
                    </Option>
                );
            }

            const convenioKey = getConvenio().key;

            this.setState({
                convenioKey,
                convenios,
                administrador: true
            });
        }
    }

    abbreviate(name) {
        var splitName = name.split(" ");
        if (splitName.length >= 3) {
            for (var i = 1; i < splitName.length - 1; i++) {
                splitName[i] = splitName[i].charAt(0) + '.';
            }
        }
        return splitName.join(" ")
    }

    UNSAFE_componentWillMount() {
        const user = currentUser();
        const convenio = getConvenio();

        let userType = this.props.userType ? this.props.userType.replace('AdministradorUsuario', 'Usuário Administrador') : '';

        if (user.displayName) {
            user.displayName = this.abbreviate(user.displayName);
            user.displayName = user.displayName.toLocaleLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
        }

        const displayName = user.email ?
            user.email.substring(0, user.email.indexOf("@")) :
            convenio.nomeFantasia.toLocaleLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });

        this.setState({
            photoURL: user.photoURL ? user.photoURL : 'https://storage.googleapis.com/bluve-bucket/base_img_avatar.png',
            userType,
            userName: user.displayName ?
                user.displayName :
                displayName,
            userEmail: user.email ?? '',
            versao: '0.1.1 | build 1.2021-07-19',
        });
    }

    btnSairClick() {
        logout();
        history.push('/login');
        history.go();
    }

    btnPerfilClick() {
        history.push('/perfil');
        history.go();
    }

    onConvenioChange(key, convenios) {
        const convenio = convenios.filter((item) => {
            return item.key === key;
        });

        changeConvenio(convenio[0]);

        history.push('/dashboard');
        history.go();
    }

    render() {
        return (
            <div className="card-profile">
                <img className="img-profile" src={this.state.photoURL} alt={this.state.userName} />
                <h1>{this.state.userName}</h1>
                <p className="title-profile">{this.state.userType}</p>

                <Tooltip placement="top" title={this.state.userEmail}>
                    <p className="pspan">{this.state.userEmail} </p>
                </Tooltip>

                {this.state.administrador && (
                    <div>
                        <Divider></Divider>

                        {this.state.convenios && (
                            <div>
                                <p>Selecionar Convênio</p>

                                <Select
                                    className='select'
                                    onChange={(key) => this.onConvenioChange(key, this.state.convenios)}
                                    value={this.state.convenioKey}
                                >
                                    {this.convenioOptions}
                                </Select>
                            </div>
                        )}
                    </div>
                )}


                <Divider></Divider>

                <p><Button type="primary" className="buttonProfile" onClick={this.btnPerfilClick}>Perfil</Button></p>
                <p><Button className="buttonProfileExit" onClick={this.btnSairClick}>Sair</Button></p>
            </div>
        )
    }
}

export default withRouter(PageLayout);



