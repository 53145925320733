import React, { useEffect, useState } from "react";
import { Tag, Avatar, Tabs, Row, Card, Button, Form, Icon } from 'antd';
import InputMask from "react-input-mask";

import profilePic from "../../assets/template/images/client/1.jpg";
import mensagem from "../../components/messages/message";

import { firebaseApp, fireBase } from '../../firebase/firebase';
import beneficiarioDAO from '../../dataManager/dtmBeneficiario';
import pontosDAO from '../../dataManager/dtmPontos';

import './styles.css';

const { Meta } = Card;
const { TabPane } = Tabs;

var mapStyle = {
    height: "150px",
    width: "100%",
    backgroundColor: "#c1eae9"
};
var cardStyle = {
    width: "100%",
    marginBottom: "20px"
};

var avatarStyle = {
    marginTop: "-50px",
    marginBottom: "20px",
    width: "100px",
    height: "100px"
};

function callback(key) {
    console.log(key);
}

const LoginForm = (props) => {
    const { getFieldDecorator } = props.form;
    const { onSubmit } = props;

    const handleSubmit = (e) => {
        e.preventDefault();

        props.form.validateFields((err, values) => {
            if (!err) {
                onSubmit(values);
            }
        });
    };

    return (
        <Form onSubmit={handleSubmit} className="login-form">
            <Form.Item>
                {getFieldDecorator('taxId', {
                    rules: [{ required: true, message: 'Digite seu CPF!' }]
                })(
                    <InputMask
                        type="tel"
                        placeholder="CPF"
                        mask="999.999.999-99"
                        className="inputText cpf"
                    />
                )}
            </Form.Item>

            <Form.Item>
                {getFieldDecorator('phone', {
                    rules: [{ required: true, message: 'Digite seu número de celular!' }]
                })(
                    <InputMask
                        placeholder="Número de celular"
                        mask={'(99) 99999-9999'}
                        className="inputText phone"
                    />
                )}
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button" style={{ width: '100%' }}>
                    Enviar Código por SMS
                </Button>
            </Form.Item>
        </Form>
    );
}

const PhoneSignInForm = Form.create()(LoginForm);

const PhoneSignIn = (props) => {
    const { onAuthenticated } = props;

    const [confirm, setConfirm] = useState(null);
    const [code, setCode] = useState('');
    const [taxId, setTaxId] = useState('');
    const [phone, setPhone] = useState('');

    let applicationVerifier;

    useEffect(() => {
        // 'recaptcha-container' is the ID of an element in the DOM.
        applicationVerifier = new firebaseApp.auth.RecaptchaVerifier(
            'recaptcha-container');
    }, []);

    const verifyUser = async (taxId) => {
        // "333.025.100-04", "(48) 12345-6789"
        const beneficiario = await beneficiarioDAO.getOneByCPFAndPhone(taxId, phone);

        if (beneficiario) {
            return true;
        }

        return false;
    };

    async function signInWithPhoneNumber(values) {
        let { taxId, phone } = values;
        let authPhone = "+55 " + phone.replace(/\D/g, "").replace(/(\d{2})(\d{5})(\d{4})/, "$1 $2-$3")

        taxId = taxId.replace(/\D/g, "").replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
        phone = phone.replace(/\D/g, "").replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");

        setTaxId(taxId);
        setPhone(phone);

        // TODO: DEBUG
        // authPhone = "+55 21 98800-7766";

        if (verifyUser(taxId, phone)) {
            try {
                const confirmation = await fireBase.auth().signInWithPhoneNumber(authPhone, applicationVerifier);
                setConfirm(confirmation);
            } catch (error) {
                console.log(error);
            }

        }
    }

    async function confirmCode() {
        try {
            await confirm.confirm(code);
            onAuthenticated(taxId, phone);
        } catch (error) {
            alert('Código inválido!');
            setCode('');
        }
    }

    if (!confirm) {
        return (
            <>
                <Row type="flex" justify="center">
                    <PhoneSignInForm onSubmit={signInWithPhoneNumber} />
                </Row>
                <Row type="flex" justify="center">
                    <div id="recaptcha-container"></div>
                </Row>
            </>
        );
    }

    return (
        <>
            <Row type="flex" justify="center">
                <InputMask
                    placeholder="Código de confirmação"
                    mask={'999-999'}
                    className="inputText confirmation"
                    onChange={event => setCode(event.target.value.replace(/\D/g, ""))}
                />
            </Row>
            <Row type="flex" justify="center" style={{ marginTop: 20 }}>
                <Button onClick={confirmCode}>
                    Confirmar Código
                </Button>
            </Row>
        </>
    );
};

const PainelCliente = () => {
    const [userAuthenticated, setUserAuthenticated] = useState(false);
    const [beneficiario, setBeneficiario] = useState('');
    const [pontos, setPontos] = useState([]);
    const [taxId, setTaxId] = useState('');
    const [phone, setPhone] = useState('');

    const getPontos = (beneficiarioId) => {
        const registroPonto = pontosDAO.getPointsCustom(beneficiarioId);
        return registroPonto;
    }

    const getRegistros = async (taxId, phone) => {
        // "333.025.100-04", "(48) 12345-6789"
        const beneficiarios = await beneficiarioDAO.getAllByCPFAndPhone(taxId, phone);
        const pontosPromise = [];

        if (!beneficiarios) {
            mensagem.openNotificationWithIcon('error', 'Beneficiário Não Encontrado', 'Verifique se o CPF e o telefone estão corretos.');
            setUserAuthenticated(false);
            return;
        }

        if (beneficiarios.length > 0) {
            beneficiarios.forEach(beneficiario => {
                const registroPonto = getPontos(beneficiario.key);
                pontosPromise.push(registroPonto);
            });

            setBeneficiario(beneficiarios[0]);

            const registrosPontos = await Promise.all(pontosPromise);
            setPontos(...registrosPontos);
        }
    };

    const handleUserAuthentication = async (taxId, phone) => {
        setTaxId(taxId);
        setPhone(phone);

        const user = fireBase.auth().currentUser;

        if (user) {
            setUserAuthenticated(true);
            getRegistros(taxId, phone);
        }
    }

    return (
        <div>
            <Row type="flex" justify="center" align="top">
                <div style={mapStyle}></div>
            </Row>
            <Row type="flex" justify="center" align="top">
                <Avatar style={avatarStyle} src={profilePic} />
            </Row>

            {!userAuthenticated && (
                <PhoneSignIn onAuthenticated={handleUserAuthentication} />
            )}

            {userAuthenticated && (
                <div>
                    <Row type="flex" justify="center">
                        <h1>{beneficiario.nome}</h1>
                    </Row>

                    <Row type="flex" justify="center" style={{ marginBottom: "30px" }}>
                        <Tag color="cyan">{`${beneficiario.nome ?? ''} ${beneficiario.sobrenome ?? ''}`}</Tag>
                    </Row>

                    <Row type="flex" justify="center" align="top">
                        <Tabs
                            size="small"
                            defaultActiveKey="1"
                            onChange={callback}
                        >
                            <TabPane tab="Programa de Pontos" key="1">
                                {pontos.map(ponto => (
                                    <Card
                                        key={ponto.key}
                                        style={cardStyle}
                                    >
                                        <Meta
                                            avatar={<Icon type="trophy" />}
                                            title={ponto.convenio}
                                            description={`${ponto.saldo} pontos`}
                                        />
                                    </Card>
                                ))}
                            </TabPane>
                        </Tabs>
                    </Row>
                </div>
            )}
        </div>
    );
}

export default PainelCliente;