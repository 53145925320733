require('../themes/Moub.css');
// require('../themes/Regina.css');


export function getLogoClass() {
    return 'logoMoub';
    // return 'logoRegina';
}


export function getColor() {
    return '#5F2A64';
    //return '#BD3029';
}

export function getSmoothColor() {
    return '#a742b1';
    //return '#f2554e';
}